/* ============================================================================================== */
/* ======================================== ANTD IMPORT ======================================== */
/* ============================================================================================== */
@import '../node_modules/antd/dist/antd.min.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ============================================================================================== */
/* ======================================== FONT IMPORTS ======================================== */
/* ============================================================================================== */
@font-face {
	font-family: 'Regular';
	src: url('assets/fonts/ProximaNova-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Bold';
	src: url('assets/fonts/ProximaNova-Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Inter', sans-serif;
	src: url('assets/fonts/Inter-VariableFont.ttf') format('ttf');
	font-weight: normal;
	font-style: normal;
}

/* iframe
{
    display: none;
} */

/* ============================================================================================== */
/* ======================================= HELPER CLASSES ======================================= */
/* ============================================================================================== */

.HorizontalFlexAlign {
	display: flex;
	align-items: center;
	justify-content: center;
}

.FullWidthHeight {
	width: 100%;
	height: 100%;
}
.FullWidth {
	width: 100%;
}
.HalfWidth {
	width: 50%;
}
.QuarterWidth {
	width: 25%;
}
.SixtyFourtyWidth {
	width: 60% !important;
}
.FourtySixtyWidth {
	width: 40% !important;
}
.SeventyThirtyWidth {
	width: 70% !important;
}
.ThirtySeventyWidth {
	width: 30% !important;
}
.ContentWidth {
	width: fit-content;
}

.FullHeight {
	height: 100%;
}
.HalfHeight {
	height: 50%;
}
.NinetyTenHeight {
	height: 90% !important;
}
.ThreeFourthWidth {
	width: 75%;
}
.QuarterHeight {
	height: 25%;
}
.HalfMaxHeight {
	max-height: 50%;
}
.QuarterMaxHeight {
	max-height: 25%;
}

.DisplayFlex {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}
.DisplayFlexSpace {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}
.FlexRow {
	display: flex;
	align-items: center;
}
.FlexColumn {
	display: flex;
	flex-direction: column;
}
.FlexRowBox {
	display: flex;
	align-items: center;
	justify-content: center;
}
.FlexColumnBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.FlexRowSpace {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.FlexColumnSpace {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.FlexRowEnd {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
.FlexRowSpaceEven {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}
.FlexWrap {
	flex-wrap: wrap;
}

.NoMargin {
	margin: 0 !important;
}
.Margin {
	margin: 24px;
}
.MarginTop {
	margin-top: 24px;
}
.MarginBottom {
	margin-bottom: 24px;
}
.MarginLeft {
	margin-left: 24px;
}
.MarginRight {
	margin-right: 24px;
}
.HalfMargin {
	margin: 12px;
}
.HalfMarginTop {
	margin-top: 12px;
}
.HalfMarginBottom {
	margin-bottom: 12px;
}
.HalfMarginLeft {
	margin-left: 12px;
}
.HalfMarginRight {
	margin-right: 12px;
}

.QuarterMarginTop {
	margin-top: 6px;
}
.QuarterMarginBottom {
	margin-bottom: 6px;
}
.QuarterMarginLeft {
	margin-left: 6px;
}
.QuarterMarginRight {
	margin-right: 6px;
}
.MarginRight8 {
	margin-right: 8px;
}
.MarginLeftRight {
	margin: 0px 24px;
}
.HalfMarginTopBottom {
	margin: 12px 0px;
}
.HalfMarginLeftRight {
	margin: 0px 12px;
}
.MarginTopBottom {
	margin: 24px 0px;
}

.MarginLeft20 {
	margin-left: 20px;
}
.MarginRight20 {
	margin-right: 20px;
}
.MarginBottom20 {
	margin-bottom: 20px;
}
.MarginHorizontal20 {
	margin: 0 20px;
}

.NoPadding {
	padding: 0 !important;
}
.Padding {
	padding: 24px;
}
.PaddingTop {
	padding-top: 24px;
}
.PaddingBottom {
	padding-bottom: 24px;
}
.PaddingLeft {
	padding-left: 24px;
}
.PaddingRight {
	padding-right: 24px;
}
.HalfPadding {
	padding: 12px;
}
.HalfPaddingTop {
	padding-top: 12px;
}
.HalfPaddingBottom {
	padding-bottom: 12px;
}
.HalfPaddingLeft {
	padding-left: 12px;
}
.HalfPaddingRight {
	padding-right: 12px;
}
.QuarterPadding {
	padding: 6px;
}
.QuarterPaddingTop {
	padding-top: 6px;
}
.QuarterPaddingBottom {
	padding-bottom: 6px;
}
.QuarterPaddingLeft {
	padding-left: 6px;
}
.QuarterPaddingRight {
	padding-right: 6px;
}

.PaddingHorizontal20 {
	padding: 0 20px;
}
.PaddingPill {
	padding: 0 6.5px;
}

.PaddingTopBottom {
	padding: 24px 0px;
}
.PaddingLeftRight {
	padding: 0px 24px;
}
.HalfPaddingLeftRight {
	padding: 0px 12px;
}
.PaddingExceptBottom {
	padding: 24px;
	padding-bottom: 0px;
}
.HalfPaddingExceptBottom {
	padding: 12px;
	padding-bottom: 0px;
}
.HalfPaddingTopBottom {
	padding: 12px 0px;
}
.HalfPaddingLeftRight {
	padding: 0px 12px;
}
.QuarterPaddingTopBottom {
	padding-top: 6px;
	padding-bottom: 6px;
}
.QuarterPaddingLeftRight {
	padding-left: 6px;
	padding-right: 6px;
}
.HalfPaddingExceptTop {
	padding: 0px 12px 12px 12px;
}
.PaddingExceptTop {
	padding: 0px 24px 24px 24px;
}

.PrimaryColor {
	color: #0066cc;
}
.PrimaryShadeColor {
	color: #003366;
}
.PrimaryTintColor {
	color: #3399ff;
}
.PrimaryHighlightColor {
	color: #e5f2ff;
}
.SecondaryColor {
	color: #00cbcb;
}
.SecondaryShadeColor {
	color: #009999;
}
.SecondaryTintColor {
	color: #00ffff;
}
.SecondaryHighlightColor {
	color: #e5ffff;
}
.GrayColor {
	color: #808080;
}
.GrayShadeColor {
	color: #666666;
}
.GrayTintColor {
	color: #999999;
}
.GrayHighlightColor {
	color: #f2f2f2;
}
.BlackColor {
	color: #333333;
}
.WhiteColor {
	color: #ffffff;
}
.ErrorColor {
	color: #ff0000;
}
.SuccessColor {
	color: #00800b;
}
.SuccessOneColor {
	color: #467d48;
}
.WarningOneColor {
	color: #a8711e;
}

.WarningColor {
	color: #a8711e;
}
.WarningOneColor {
	color: #f57f17;
}
.PrimaryBlueColor {
	color: #1c75bc;
}
.MainBlackColor {
	color: #c8c8c8;
}
.ErrorTwoColor {
	color: #f44336;
}
.MainBlackOneColor {
	color: #676767;
}
.BrandOneColor {
	color: #00c2cb;
}

.PBackground {
	background-color: #0066cc;
}
.PSBackground {
	background-color: #1c75bc;
}
.PTBackground {
	background-color: #3399ff;
}
.PHBackground {
	background-color: #e5f2ff;
}
.SHBackground {
	background-color: #e5ffff;
}
.GHBackground {
	background-color: #f2f2f2 !important;
}
.WBackground {
	background-color: #ffffff;
}
.EHBackground {
	background-color: #ffe5e6;
}
.SBackground {
	background-color: #00800b;
}

.SuccessOneBg {
	background-color: #467d48;
}
.SuccessFourBg {
	background-color: #e4f7e5;
}
.PrimaryBlueBg {
	background-color: #1c75bc;
}
.BlueTintBg {
	background-color: #edf7ff;
}
.WaringTwoBg {
	background-color: #ffc700;
}
.WarningOneBg {
	background-color: #a8711e;
}
.WarningFourBg {
	background-color: #fff7d9;
}
.MainBlackFiveBg {
	background-color: #f8f8f8;
}
.MainBlackOneBg {
	background-color: #676767;
}

.RegularFont {
	font-family: 'Regular';
}
.BoldFont {
	font-family: 'Bold';
}
.FontSize10 {
	font-size: 10px;
}
.FontSize12 {
	font-size: 12px;
}
.FontSize14 {
	font-size: 14px;
}
.FontSize16 {
	font-size: 16px;
}
.FontSize18 {
	font-size: 18px;
}
.FontSize20 {
	font-size: 20px;
}
.FontSize30 {
	font-size: 30px;
}
.Capitalize {
	text-transform: capitalize;
}
.LeftAlign {
	text-align: left;
}
.RightAlign {
	text-align: right;
}
.CenterAlign {
	text-align: center;
}

.NoBorder {
	border: 0px;
}
.Border {
	border: 1px solid #e5e5e5;
}
.BorderTop {
	border-top: 1px solid #e5e5e5;
}
.BorderBottom {
	border-bottom: 1px solid #e5e5e5;
}
.BorderLeft {
	border-left: 1px solid #e5e5e5;
}
.BorderRight {
	border-right: 1px solid #e5e5e5;
}
.BorderRadius {
	border-radius: 5px;
}
.BorderRadiusCircle {
	border-radius: 100px;
}
.BorderRadiusMed { border-radius: 20px;}

.NoBorderRadius {
	border-radius: 0px !important;
}
.BorderRadiusTop {
	border-radius: 5px 5px 0px 0px !important;
}
.BorderTopDashed {
	border: 1px dashed #c8c8c8;
}

.NoOpacity {
	opacity: 0;
}
.OverflowScroll {
	overflow-y: scroll;
	overscroll-behavior: none;
}
.CursorPointer {
	cursor: pointer;
}
.Link {
	text-decoration: underline;
	cursor: pointer;
}
.DisplayNone {
	display: none;
}
.NoWrap {
	white-space: nowrap;
}

/* ============================================================================================== */
/* ======================================== BASE CLASSES ======================================== */
/* ============================================================================================== */
html,
body,
#root {
	width: 100vw;
	height: 100vh;
	font-family: 'Regular' !important;
	font-size: 14px;
	color: #333333 !important;
	padding: 0px;
	margin: 0px;
	overscroll-behavior: none;
	/* user-select: none; */
}

p {
	/* color: #333333;  */
	margin: 0px;
}
a:hover {
	text-decoration: none;
	color: #0066cc;
}

td {
	vertical-align: top;
}

/* ============================================================================================== */
/* ======================================= LAYOUT CLASSES ======================================= */
/* ============================================================================================== */
.ant-layout {
	width: 100%;
	height: 100%;
	/* background-color: #FFFFFF; */
}
.ant-layout-header {
	height: auto;
	background-color: #ffffff;
	line-height: 120%;
}

/* ============================================================================================== */
/* ======================================= HEADER CLASSES ======================================= */
/* ============================================================================================== */
.PrimaryHeader.ant-layout-header {
	height: 72px;
}
.SecondaryHeader.ant-layout-header {
	height: 60px;
}

.ant-breadcrumb-separator {
	font-family: 'Bold';
	font-size: 18px;
}

.HeaderLogo {
	width: auto;
	height: 30px;
}

.ProfileInfo {
	height: 36px;
	border-radius: 100px;
}

.ProfileMenu {
	border-radius: 10px;
}
.ProfileMenu > .ant-dropdown-menu-item {
	height: 36px;
	display: flex;
	align-items: center;
}
.ProfileMenu > .ant-dropdown-menu-item:first-child {
	border-radius: 5px 5px 0px 0px;
}
.ProfileMenu > .ant-dropdown-menu-item:last-child {
	border-radius: 0px 0px 5px 5px;
}

.ant-dropdown-menu-title-content {
	display: flex;
	align-items: center;
}

/* ============================================================================================== */
/* ======================================= SIDEBAR CLASSES ====================================== */
/* ============================================================================================== */
.ant-layout-sider {
	height: 100%;
	background-color: #ffffff;
	border-right: 1px solid #e5e5e5;
}

.SidebarIcon {
	width: 20px;
	height: 20px;
	margin-right: 12px;
	fill: #0066cc;
}

.ant-menu-item {
	height: 60px !important;
	font-family: 'Bold';
	margin: 0px !important;
	padding: 0px !important;
	padding-left: 24px !important;
	padding-right: 24px !important;
}
.ant-menu-inline {
	border: 0px;
}
.ant-menu-inline .ant-menu-item::after {
	border-right: 3px solid #0066cc;
}
.ant-menu-item-selected {
	background: #e5f2ff;
}

/* ============================================================================================== */
/* ======================================= TABLE SETTINGS ======================================= */
/* ============================================================================================== */
.ant-table {
	margin: 0px !important;
}
.ant-table-thead > tr > th {
	font-family: 'Bold';
}

.ant-table-expanded-row > .ant-table-cell {
	padding: 0px !important;
}
td.ant-table-column-sort {
	background: #ffffff;
}

.ant-table-wrapper > div,
.ant-table-wrapper > div > div,
.ant-table-wrapper > div > div > div,
.ant-table-wrapper > div > div > div > div {
	height: 100%;
}
.ant-table-wrapper .ant-spin-nested-loading > div {
	height: auto;
}
.ant-table-wrapper .ant-spin-container {
	height: 100% !important;
}

.MainTable {
	height: calc(100% - 48px);
}
.MainTable .ant-table-thead > tr > th {
	color: #666666;
	background-color: #e5f2ff;
}
.MainTable .ant-table-thead th.ant-table-column-has-sorters:hover {
	background-color: #e6e6e6;
}

.ant-table-tbody > tr.ant-table-row-selected > td,
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
	background-color: #f5faff;
}

.SelectedRow.GHBackground td,
.SelectedRow.GHBackground td:hover {
	background: #f2f2f2 !important;
}

.ant-table-cell {
	vertical-align: middle;
}

.SecondaryTable {
	height: calc(100% - 48px);
}
.SecondaryTable .ant-table-thead > tr > th {
	color: #666666;
	background-color: #e5f2ff;
	/* text-align: center; */
}
.SecondaryTable .ant-table-thead th.ant-table-column-has-sorters:hover {
	background-color: #e6e6e6;
}

/* ============================================================================================= */
/* ========================================== BUTTONS ========================================== */
/* ============================================================================================= */
.Button,
.Button:hover,
.Button:active,
.Button:focus {
	min-width: max-content;
	height: 48px;
	font-family: 'Bold';
	font-size: 16px;
	color: #ffffff;
	border: 0;
	border-radius: 5px;
	padding: 0px 12px;
}

.PrimaryButton,
.PrimaryButton:hover,
.PrimaryButton:active,
.PrimaryButton:focus {
	background-color: #0066cc;
}
.SecondaryButton,
.SecondaryButton:hover,
.SecondaryButton:active,
.SecondaryButton:focus {
	color: #333333;
	background-color: #f2f2f2;
	border-radius: 5px;
}
.SuccessButton,
.SuccessButton:hover,
.SuccessButton:active,
.SuccessButton:focus {
	background-color: #00800b;
}

.PHButton,
.PHButton:hover,
.PHButton:active,
.PHButton:focus {
	color: #0066cc;
	background-color: #e5f2ff;
}
.SHButton,
.SHButton:hover,
.SHButton:active,
.SHButton:focus {
	color: #00800b;
	background-color: #e5ffe8;
}
.EHButton,
.EHButton:hover,
.EHButton:active,
.EHButton:focus {
	color: #ff0000;
	background-color: #ffe5e6;
	border: 1px solid #ff0000;
	border-radius: 5px;
}

.DashedButton,
.DashedButton:hover,
.DashedButton:active,
.DashedButton:focus {
	font-size: 14px;
	color: #808080;
	background-color: #ffffff;
	border: 1px dashed #808080;
	padding: 0px 24px;
}

/* ============================================================================================= */
/* ======================================= MISCELLANEOUS ======================================= */
/* ============================================================================================= */
.CountTag {
	min-width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100px;
	margin-right: 12px;
}
.ant-radio-button-wrapper .CountTag {
	color: #808080;
	background-color: #f2f2f2;
}
.ant-radio-button-wrapper-checked .CountTag {
	color: #0066cc !important;
}
/* ======== (Un)Invoiced Orders Radio ======== */
.InvoiceRadioGroup > label:first-of-type {
	border-radius: 100px 0px 0px 100px;
}
.InvoiceRadioGroup > label:last-of-type {
	border-radius: 0px 100px 100px 0px;
}
.InvoiceRadioGroup > .ant-radio-button-wrapper {
	height: 48px;
	width: max-content;
	display: flex;
	align-items: center;
	padding: 0px 12px;
}
.InvoiceRadioGroup > .ant-radio-button-wrapper > span {
	display: flex;
	align-items: center;
}

.ant-radio-button-wrapper-checked,
.ant-radio-button-wrapper-checked::before,
.ant-radio-button-wrapper-checked:focus-within {
	background-color: #0066cc !important;
	border-color: #0066cc !important;
	box-shadow: none !important;
}

/* ============================================================================================ */
/* ======================================== FORM ITEMS ======================================== */
/* ============================================================================================ */
.ant-form-item-label {
	font-family: 'Bold';
	padding-bottom: 0px !important;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	display: none;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
	display: inline-block;
	content: '*';
	color: #ff0000;
	margin-left: 6px;
}

.InputField > .ant-input-group,
.InputNumberField .ant-input-number-group,
.InputNumberField .ant-input-number-input-wrap,
.InputNumberField input {
	height: 100%;
}
.InputField .ant-input-group-addon,
.InputNumberField .ant-input-number-group-addon {
	height: 100%;
	font-family: 'Bold';
	color: #333333;
	background-color: #f2f2f2;
	border: 0;
	padding: 0px 12px;
}
.InputField .ant-input,
.InputNumberField .ant-input-number {
	height: 100%;
	border: 0;
}
.InputField > .ant-input-group > *:first-child,
.InputNumberField > .ant-input-number-group > *:first-child {
	border-radius: 5px 0px 0px 5px !important;
}
.InputField > .ant-input-group > *:last-child,
.InputNumberField > .ant-input-number-group > *:last-child {
	border-radius: 0px 5px 5px 0px !important;
}
.ant-form-item-has-error .InputField {
	border: 1px solid #ff0000 !important;
}
.ant-form-item-has-error .InputNumberField.ant-input-number-group-wrapper {
	border: 1px solid #ff0000 !important;
}
.ant-form-item-has-error > .ant-form-item-control > div > div > span > .UploadImages {
	border: 1px dashed #ff0000 !important;
}
.ant-form-item-has-error .UploadImages .ant-btn {
	border: 1px solid #ff0000 !important;
}

.ant-btn-primary {
	color: #1890ff;
}

/* ============================================================================================== */
/* ======================================== INPUT FIELDS ======================================== */
/* ============================================================================================== */
.InputField,
.PasswordField,
.InputNumberField.ant-input-number-group-wrapper {
	width: 100%;
	height: 48px;
	min-height: 48px !important;
	font-family: 'Regular';
	display: flex;
	align-items: center;
	border: 1px solid #e0e0e0;
	border-radius: 5px !important;
	color: #333333;
}

.SearchField {
	color: #333333;
	background-color: #f2f2f2;
	border: 1px solid #e0e0e0;
	border-radius: 5px !important;
}
.SearchField .ant-input {
	width: 50%;
	height: 38px !important;
	background-color: #f2f2f2;
}

.PasswordField {
	padding: 0px;
}
.PasswordField > input {
	padding-left: 12px !important;
	border-radius: 5px;
}
.PasswordField > span {
	margin-left: 12px !important;
	margin-right: 12px !important;
}

.InputField > .ant-input-number-input-wrap {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}

.UploadImages > .ant-upload {
	width: 100%;
	border: 1px solid #e0e0e0;
	border-radius: 5px;
}

.Pill {
	width: max-content;
	height: 32px;
	border-radius: 100px;
}
.HeaderPill {
	background-color: rgba(0, 0, 0, 0.3);
	border: 1px #ffffff;
}

.StatusPill {
	width: max-content;
	font-family: 'Bold';
	font-size: 12px;
	display: flex;
	align-items: center;
	margin-top: 4px;
	padding: 0px 12px;
	border-radius: 100px;
}

.CorporatePill {
	font-size: 10px;
	color: #ffffff;
	background-color: #003366;
}

/* ============================================================================================== */
/* ====================================== TABLE PAGINATION ====================================== */
/* ============================================================================================== */
.ant-pagination-prev,
.ant-pagination-next {
	border: 0px;
}
.ant-pagination-item {
	font-family: 'Bold';
}
.ant-pagination-item:hover a {
	color: #0066cc;
}
.ant-pagination-item-active {
	background-color: #0066cc;
}
.ant-pagination-item-active a {
	color: #ffffff !important;
}

.ant-steps-item-title {
	font-family: 'Bold';
	font-size: 12px !important;
}
.ant-steps-item-description {
	font-size: 12px !important;
}

.Circle24 {
	width: 24px;
	height: 24px;
}
.SiderButton {
	position: absolute;
	right: 0px;
	margin-top: 18px;
	margin-right: -12px;
	z-index: 1;
}

.LineLimit1 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.ant-btn[disabled] {
	background-color: #d9d9d9;
}

.ant-popover-inner-content {
	padding: 24px 0px 0px 0px;
}
.ant-timeline-item {
	padding-bottom: 12px;
}
.ant-timeline-item-last > .ant-timeline-item-content {
	min-height: auto;
}
.ant-timeline-item.ant-timeline-item-last {
	padding-bottom: 0px;
}

.ant-comment-inner {
	padding-top: 0px;
}

.ant-tabs-tab,
.ant-tabs-nav {
	font-family: 'Bold';
	margin: 0px !important;
	border-left: 0px !important;
	border-top: 0px !important;
	border-right: 1px solid #e0e0e0;
	border-radius: 0px !important;
}

.ant-popover-title {
	font-family: 'Bold';
}

.and-modal-zero-padding .ant-modal-body {
	padding: 0;
}

.ant-modal-header {
	background-color: #edf7ff;
}

.ant-modal-header .ant-modal-title {
	color: #0066cc;
	font-weight: 600;
}

.DisableLink {
	pointer-events: none;
	color: #c8c8c8;
}

.Hidden {
	display: none;
}
/* ==================================== Pagination =========================================== */

.PaginationItem {
	border: 1px ridge #ffffff;
	text-align: center;
	padding: 6px 10px;
	margin: 0px 4px;
	color: rgba(0, 0, 0, 0.87);
	align-items: center;
	font-size: 13px;
	background-color: #ffffff;
	border-radius: 2px;
}

.PaginationItemArrow {
	border: 1px ridge #ffffff;
	text-align: center;
	padding: 7px 8px 5px 8px;
	margin: 0px 4px;
	color: rgba(0, 0, 0, 0.87);
	align-items: center;
	font-size: 13px;
	background-color: #ffffff;
	border-radius: 2px;
}

.PaginationItemDots {
	text-align: center;
	padding: 6px 8px;
	margin: 0px 4px;
	color: rgba(113, 113, 113, 0.87);
	align-items: center;
	font-weight: 900;
	font-size: 20px;
	border-radius: 2px;
}

.Disabled {
	cursor: not-allowed;
}

.Selected {
	border: 1px solid #1c75bc;
	font-weight: 600;
	color: #1c75bc;
}

.SelectedDropDown {
	font-weight: 600;
	color: #1c75bc;
}

.PaginationItemDots:hover {
	color: #1c75bc;
}

.OrderTile {
	margin-left: 6px;
	margin-right: 6px;
	border-radius: 12px;
	background-color: #ffffff;
}

.FooterPadding {
	padding-bottom: 1em !important;
	padding-top: 0 !important ;
	margin-top: -2em !important;
}

.specialMarginRight {
	/* margin-right: 2em !important; */
	margin-left: -0.5em !important;
	/* padding: 1em !important; */
	/* padding-left: 2em; */
}